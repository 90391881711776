import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html'
})
export class BlankComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
