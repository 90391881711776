import { Injectable, Inject } from '@angular/core';
import { Login } from '../models/login';
import { Register } from '../models/register';
import { Forgot } from '../models/forgot';
import { Reset } from '../models/reset';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // apiUrl = 'https://binghan-go.gruperdev.com';
  apiUrl = environment.apiURL;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }


  login(data: Login): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/v1/admins/login`, data, this.httpOptions).pipe(
      tap(_ => console.log('data fetched')),
      catchError(this.handleError<Login>('login'))
    );
  }

  register(data: Register): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/auth/register`, data, this.httpOptions).pipe(
      tap(_ => console.log('data fetched')),
      catchError(this.handleError<Register>('register'))
    );
    // return of (data);
  }

  forgot(data: Forgot): Observable<any> {
    return of (data);
  }

  reset(data: Reset): Observable<any> {
    return of(data);
  }

  isVerified(): boolean {
    let result = false;

    const token = this.storage.get('token');
    if (token) {
      result = true;
    }

    return result;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
      } else {
        errorMessage = ` ${error.error.msg}`;
      }
      // alert(`${operation} failed: ${errorMessage}`);
      return throwError(errorMessage);
    };
  }
}
