import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES,ADMINROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from "../../../app/services/local-storage.service";
import { Member } from '../../../app/models/member';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private storage: LocalStorageService,
  ) { }
  // End open close
  ngOnInit() {
    const adminMember: Member = JSON.parse(this.storage.getData());
    if (adminMember.is_super_admin) {
      this.sidebarnavItems = ADMINROUTES.filter(sidebarnavItem => sidebarnavItem);
    } else {
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
  }
}
