import { Component, OnInit } from "@angular/core";
import { MessagingService } from "./services/messaging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  message;

  constructor(private msgService: MessagingService) {}

  ngOnInit() {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("safari") == -1) {
      this.msgService.getPermission();
      this.msgService.receiveMessage();
      this.message = this.msgService.currentMessage;
    }
  }
}
