// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiURL: 'http://192.168.1.6:8000',
  // apiURL: 'http://localhost:8000',
  // apiURL: 'http://binghan-go.gruperdev.com'
  // apiURL: 'http://52.221.202.128'
  apiURL: 'https://api.binghan.id'
};
