import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './guards/auth.guard';

export const Approutes: Routes = [

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/invoice', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      {
        path: 'member',
        loadChildren: () => import('./pages/register-member/member-register.module').then(m => m.MemberRegisterModule)
      },
      {
        path: 'member-approve',
        loadChildren: () => import('./pages/member-approval/member-approval.module').then(m => m.MemberApprovalModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule)
      },
      {
        path: 'payment-receive',
        loadChildren: () => import('./pages/payment-receive/payment-receive.module').then(m => m.PaymentReceiveModule)

      },
      {
        path: 'invoice',
        loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'transfer-pv',
        loadChildren: () => import('./pages/transfer-pv/transfer-pv.module').then(m => m.TransferPvModule)
      },
      {
        path: 'promote',
        loadChildren: () => import('./pages/promote/promote.module').then(m => m.PromoteModule)
      },
      {
        path: 'pv-movement',
        loadChildren: () => import('./pages/pv-movement/pv-movement.module').then(m => m.PVMovementModule)
      },
      {
        path: 'bonus',
        loadChildren: () => import('./pages/bonus/bonus.module').then(m => m.BonusModule)
      },
      {
        path: 'broadcast',
        loadChildren: () => import('./pages/broadcast/broadcast.module').then(m => m.BroadcastModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule)
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/page.module').then(m => m.PageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'forgot',
        loadChildren: () => import('./pages/forgotpass/forgot.module').then(m => m.ForgotModule)
      },
      {
        path: 'resetpassword',
        loadChildren: () => import('./pages/resetpass/reset.module').then(m => m.ResetModule)
      },
      {
        path: 'term-and-condition',
        loadChildren: () => import('./pages/term-and-condition/term-and-condition.module').then(m => m.TermAndConditionModule)
      },
      {
        path: 'invoice-print',
        loadChildren: () => import('./pages/invoice-print/invoice-print.module').then(m => m.InvoicePrintModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];
