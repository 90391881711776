import { Component, AfterViewInit, EventEmitter, Output, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { LocalStorageService } from "../../services/local-storage.service";
import { MessagingService } from '../../services/messaging.service';

declare var $: any;
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html"
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public user_name: string;
  public user_email: string;
  public config: PerfectScrollbarConfigInterface = {};
  public showSearch = false;
  public isNotify: boolean = true;
  public listNotification: any[];

  // This is for Notifications
  notifications: Object[];
  //   notifications: Object[] = [
  //   {
  //     round: "round-danger",
  //     icon: "ti-link",
  //     title: "Launch Admin",
  //     subject: "Just see the my new admin!",
  //     time: "9:30 AM"
  //   },
  // ];

  constructor(
    private storage: LocalStorageService,
    private router: Router,
    private messagingService: MessagingService,
  ) { }

  ngOnInit() {
    this.user_name = JSON.parse(this.storage.getData()).full_name;
    this.user_email = JSON.parse(this.storage.getData()).email;
    this.getNotification();
  }

  getNotification() {
    var me = JSON.parse(this.storage.getData());
    return this.messagingService.getNotification(me.admin_id, 1, 5, "").subscribe((data) => {
      this.listNotification = data.data.list;
    });
  }

  logOut() {
    this.storage.removeToken();
    this.router.navigateByUrl("/login");
  }
}
