import { Injectable } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { tap, catchError, map } from 'rxjs/operators';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Broadcast } from '../models/broadcast';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private storage: LocalStorageService,
    private toastr: ToastrService,
    ) { }

  apiURL = environment.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.storage.getToken()
    })
  };

  private updateToken(token) {
    // this.afAuth.authState.take(1).subscribe(user => {
    //   if (!user) { return; }

    // const data = { [user.uid]: token };

    const data = { "firebase_token": token };
    var id = JSON.parse(this.storage.getData()).id;
    this.updateTokenFirebase(id, data).subscribe(res => {
      console.log(res);
    });
    // console.log(data);
    // this.db.object('fcmToken/').update(data);
    // });
  }

  updateTokenFirebase(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiURL}/api/v1/admins/${id}`, data, this.httpOptions).pipe(
      map(result => {
        return result.msg;
      })
    )
  }

  getPermission() {
    let messaging = firebase.messaging();
  
    // this.messaging.getToken()
    //   .then(() => {
    //     console.log('Notification permission granted');
    //     return this.messaging.getToken();
    //   })
    //   .then(token => {
    //     this.updateToken(token);
    //     console.log("Token ", token);
    //   })
    //   .catch((err) => {
    //     console.log('Unable to get permission to notify.', err);
    //   });
    var self = this;
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        messaging.getToken()
          .then(() => {
            console.log('Notification permission granted');
            return messaging.getToken();
          })
          .then(token => {
            self.updateToken(token);
            console.log("Token ", token);
          })
          .catch((err) => {
            console.log('Unable to get permission to notify.', err);
          });
      }
    });
  }

  receiveMessage() {
    let messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      console.log('Message receive. ', payload);
      this.toastr.info(payload.notification.title,payload.notification.body);
      this.currentMessage.next(payload);
      // new Notification(payload.notification.title);
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      let errorMessage = '';
      console.log(error);
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
      } else {
        errorMessage = ` ${error.error.msg} [${error.error.code}]`;
      }
      alert(`${operation} failed: ${errorMessage}`);
      return of(result as T);
    };
  }

  sendMessage(data): Observable<any> {

    // var data = {
    //   "notification": {
    //     "title": "Hello World",
    //     "body": "This is Message from Admin"
    //   },
    //   "data": {
    //     "Id": "ADM0001"
    //   },
    //   "to": "c7cHA992Vco:APA91bEoeUjcUvBESCpbTPAEY4KCSsZVoUXnnK2me0BQrfoY7bPaIRjOWGJs_RRAoTs6GJNOs5KwwT48ha3vLYcL2QqjWtPzdkEgyqO8Fxwf6WdG9zB1XgHtFTlk0KqF8gyRsu3-YpPx"
    // };

    return this.http.post<any>(`${this.apiURL}/api/v1/notification/push-notification`, data, this.httpOptions).pipe(
      map(result => {
        const msg = result.msg;
        return msg;
      }),
      catchError(this.handleError('notif'))
    );
  }

  getAll(page: number, perpage: number, filter: string): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/api/v1/notification/admin?page=${page}&perpage=${perpage}&filter=${filter}`, this.httpOptions).pipe(
      tap(_ => console.log('get data success')),
      catchError(this.handleError('notif'))
    );
  }

  getNotification(binghanId:string,page: number, perpage: number, filter: string): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/api/v1/notification/member/${binghanId}?page=${page}&perpage=${perpage}&filter=${filter}`, this.httpOptions).pipe(
      tap(_ => console.log('get data success')),
      catchError(this.handleError('notif'))
    );
  }

  add(data: Broadcast): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/api/v1/notification/broadcast`, data, this.httpOptions).pipe(
      map(result => {
        const msg = result.msg;
        return msg;
      }),
      catchError(this.handleError('notif'))
    );
  }
}
